<template>
  <div class="loginbox">
    
  </div>
</template>
<script>
export default {
  data() {
    return {
      select_quhao: '',
      option: [],
      loginform: {
        username: '',
        password: '',
        password2: '',
        sms: ''
      },
      type: 'password',
      loading: false,
      checked: true,
      eye: 'closed-eye',
      sendsmsbtn: true,
      sendsmstime: 60,
      sendemailbtn: true,
      sendemailtime: 60
    }
  },
  created() {
    //this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/home/index/getcode')
      if (data) {
        if (data.code === 200) {
          this.option = data.data
          this.select_quhao = data.data[0].value
        }
      }
    },
    // 查看密码
    openeye(event) {
      if (this.type === 'password') {
        this.eye = 'eye'
        this.type = 'text'
      } else {
        this.eye = 'closed-eye'
        this.type = 'password'
      }
    },
    // 手机号找回
    phone() {
      this.loading = true
    },
    async sendsms() {
      if (!this.loginform.username) {
        this.$toast.fail(this.$t('forget.enterphone'))
        return false
      }
      if (this.sendsmsbtn === false) {
        return false
      }
      const { data } = await this.$http.post('/home/index/sendsms', {
        phone: this.loginform.username,
        area: this.select_quhao
      })
      if (data) {
        if (data.code === 200) {
          this.sendsmsbtn = false
          this.sendsmstime = 60
          this.$toast.success(this.$t('forget.codesent'))
          const smsTimer = setInterval(() => {
            this.sendsmstime--
            if (this.sendsmstime <= 0) {
              this.sendsmsbtn = true
              clearInterval(smsTimer)
            }
          }, 1000)
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    // 邮箱找回
    email() {
      this.emailloading = true
    },
    async sendemail() {
      if (!this.loginform.username) {
        this.$toast.fail(this.$t('forget.enteremail'))
        return false
      }
      if (
        !/^([a-zA-Z]|[0-9])(\w)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/.test(
          this.loginform.username
        )
      ) {
        this.$toast.fail(this.$t('forget.emailformat'))
        return false
      }
      if (this.sendemailbtn === false) {
        return false
      }
      const { data } = await this.$http.post('/home/index/sendemail', {
        email: this.loginform.username
      })
      if (data) {
        if (data.code === 200) {
          this.sendemailbtn = false
          this.sendemailtime = 60
          this.$toast.success(this.$t('forget.codesent'))
          const eaminTimer = setInterval(() => {
            this.sendemailtime--
            if (this.sendemailtime <= 0) {
              this.sendemailbtn = true
              clearInterval(eaminTimer)
            }
          }, 1000)
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    },
    async getpass() {
      if (
        !this.loginform.password ||
        !this.loginform.sms ||
        !this.loginform.username
      ) {
        this.$toast.fail(this.$t('forget.complete'))
        return false
      }
      if (this.loginform.password !== this.loginform.password2) {
        this.$toast.fail(this.$t('forget.eqpass'))
        return false
      }
      const { data } = await this.$http.post(
        '/home/index/getpass',
        this.loginform
      )
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('commom.success'))
          this.$router.push('/web/index/login')
        } else {
          this.$toast.fail(this.getlang(data.msg, localStorage.getItem('lang')))
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>

</style>
